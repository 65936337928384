<template>
  <svg width="58" height="58" class="text-white" viewBox="0 0 602 602" xmlns="http://www.w3.org/2000/svg">
    <path class="fill-current" d="M391.45 362.02C420.22 334.89 438.18 296.51 438.18 253.97C438.18 172.22 371.28 105.41 289.2 105.19L124.49 105.19C83.8 105.19 48.27 92.76 21.97 59.6C11.67 46.6 4.35 32.32 0 17.75L49.39 0C52.12 10.37 57.14 20.22 62.86 27.43C79.21 48.04 100.6 53.29 124.93 53.29L289.34 53.29C400.13 53.58 490.29 143.62 490.29 253.97C490.29 312.27 465.28 364.78 425.37 401.46L391.45 362.02ZM341.12 402.74L448.22 527.36C461.57 542.89 481.3 550.82 501.83 550.04C521.83 549.27 549.15 537.99 556.87 517.77L602 543.75C585.47 576.51 548.05 600.21 503.83 601.9C466.1 603.34 432.53 588.92 408.03 560.41L317.13 454.64L252.29 454.64L252.29 571.12L83.23 571.12C83.23 437.34 83.23 303.56 83.23 169.77C151.83 169.77 220.43 169.77 289.03 169.77C335.39 169.89 373.34 207.8 373.34 253.97C373.34 300.38 335.61 338.04 289.03 338.16L200.18 338.16L200.18 286.26L289.03 286.26C306.76 286.14 321.23 271.62 321.23 253.97C321.23 236.21 306.83 221.8 289.03 221.68L135.34 221.68L135.34 519.22L200.18 519.22L200.18 402.74L341.12 402.74Z" />
  </svg>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
</style>
