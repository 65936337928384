<template>
  <div class="flex justify-center items-center w-full h-full">
    <logo-icon></logo-icon>
  </div>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>

</style>
