import createAppInstance from '@/apps/base';
import { registerRoutes } from '@/core';
import routes from './routes';

const PortfolioModule = {
  install: () => {
    registerRoutes(routes);
  },
};

createAppInstance(PortfolioModule);
