import { mapGetters } from 'vuex';

const colorSchemeMixin = {
  computed: {
    ...mapGetters(['colorScheme']),
  },
  watch: {
    colorScheme(newScheme, actualScheme) {
      const htmlElement = document.querySelector('html');
      if (actualScheme !== null) {
        htmlElement.classList.remove(actualScheme);
      }
      htmlElement.classList.add(newScheme);
    },
  },
};

export default colorSchemeMixin;
