import colorScheme from '../models/color-scheme';

const init = ({ commit }) => {
  if (localStorage.theme === colorScheme.DARK || (!('theme' in localStorage) && window.matchMedia(`(prefers-color-scheme: ${colorScheme.DARK})`).matches)) {
    commit('SET_COLOR_SCHEME', colorScheme.DARK);
  } else {
    commit('SET_COLOR_SCHEME', colorScheme.LIGHT);
  }
};

const setColorScheme = ({ commit }, value) => {
  commit('SET_COLOR_SCHEME', value);
  localStorage.setItem('theme', value);
};

export default {
  init,
  setColorScheme,
};
